<template>
    <div class="p-2 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8">
        <div v-for="answer in answers" class="min-h-[5rem] transition duration-500 ease-in-out rounded bg-white hover:bg-blue-100 bg-gray-100 transform hover:-translate-y-1 hover:scale-105 w-full lg:max-w-full lg:flex shadow" data-test="list-items">
                <div class="p-2">
                    <div class="pt-1 font-light" data-test="answer-count-message">
                        Provided <span class="mx-1 px-2 py-1 text-xs font-bold border border-gray-600 rounded-full">{{ answer.answerCount}}</span> {{ pluralizeWord(answer.answerCount, 'time') }}
                        <p class="mt-2 font-light" data-test="score">Score: {{ answer.score }}</p>
                    </div>
                    <div class="my-4 text-base" data-test="answer">
                        <p>{{ JSON.parse(answer.answer)[0] }}</p>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Free',
    props: {
        answers: {
            type: Object,
            required: true,
        },
    },
}
</script>