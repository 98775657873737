<template>

  <input v-model="search" id="search" name="search" class="form-input shadow-none border-none w-32 md:w-64 rounded-md pl-10 pr-4 focus:border-indigo-600 text-xs md:text-base" type="text" placeholder="Search CALs">

</template>

<script>

export default {
    name: "Search",
    props: ['default'],
    data() {
      return {
        search: this.default
      }
    },
    methods: {
      calSearch(e) {
        if (!this.isEmptyStringOrOnlyContainsSpaces(this.search)) {
          return true;
        }

        e.preventDefault();
      },
      isEmptyStringOrOnlyContainsSpaces(str) {
        return str.replace(/\s/g, '').length === 0;
      },
    },
}

</script>
