<template>
    <div ref="wholePage">
        <div class="mt-4" id="page" v-html="questionText"></div>
        <span class="sr-only assistive-text">
            For the list of answers, select those which you think are true.
        </span>
        <div>
            <div v-if="submitted === true" class="question-feedback-text" role="alert">
                <div class="flex">
                    <div class="py-1">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                    </div>
                    <div>
                        <p v-html="feedbackText"></p>
                        <p>{{ scoreMessage }} </p>
                    </div>
                </div>
            </div>
            <div>
                <div class='bg-gray-300 rounded-lg option-group'>
                    <div v-for='option in options' :key='option.index' data-test='option' class='pair-item'  v-bind:class="[submitted ? option.responseClass() : '']">
                        <div class="md:flex md:items-center md:justify-between">
                            <div v-html="option.text"></div>
                            <div class="relative" v-if="submitted === false">
                                <component :option="option" class="relative z-10 block rounded px-2 focus:outline-none" :is="responseComponent" @answerSelected="onAnswerSelected" />
                            </div>
                        </div>
                        <div v-if="submitted === true" >
                            <div>Option {{ option.index }}.  {{ option.is_correct ? 'Correct': 'Incorrect' }}</div>
                            <div v-html="option.feedback_text"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-8 pt-2 pb-4">
                <button data-test="submit-answers" class="p-2 btn btn-edit" :disabled="canSubmit === false" @click="submitAnswers">Submit</button>
            </div>
        </div>
    </div>
</template>

<script>

import McqMultipleAnswer from './elements/McqMultipleAnswer'
import McqTrueFalseAnswer from './elements/McqTrueFalseAnswer'
import md5 from 'md5'
import axios from 'axios'

export default {
    name: "Mcq",
    props: {
      type: {
        type: String,
        required: true,
        validator: function (value) {
          return ['truefalse', 'multiple'].indexOf(value) !== -1
        }
      },
      feedbackText: {
        type: String,
        required: true,
      },
      optionsArray: {
        type: Array,
        required: true,
      },
      scoringType: {
        type: Number,
        validator: function (value) {
          return [1,2,3].indexOf(value) !== -1
        },
        required: true,
      },
      maxScore: {
        type: Number,
        required: true,
      },
      pageId: Number,
      calId: Number,
      questionText: {
        type: String,
        required: true,
      },
      public: {
            type: Boolean,
            required: false,
        },
    },
    data() {
      return {
        options: this.optionsArray.map(option => this.initOption(option)),
        submitted: false,
        calScorerElement: null,
      }
    },
    mounted: function () {
      this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
    },
    components: {
        McqMultipleAnswer,
        McqTrueFalseAnswer
    },
    mounted() {
     this.calScorerElement = document.getElementById('cal-score');
    },
    computed: {
        responseComponent() {
            return this.type === 'truefalse' ? McqTrueFalseAnswer : McqMultipleAnswer
        },
        calculatedScore() {
          let score = 0;

          switch (`${this.type}${this.scoringType}`) {
            case 'truefalse1':
              //Positively scored - correctly selected true and false options
              score = this.options.filter(option => option.is_correct === true).length
              break;
            case 'truefalse2':
              //Negatively scored - you gain a point for a correct answer, but lose a point for an incorrect answer
              score = this.options.filter(option => option.is_correct === true).length - this.options.filter(option => option.is_correct === false).length
              break;
            case 'multiple1':
              //Positively scored - is correct and original was true
              score = this.options.filter(option => option.is_correct === true && option.actual === 1).length
              break;
            case 'multiple2':
              //Negatively scored
              // - you gain a point for correctly selecting a true option
              // - you lose a point for incorrectly selecting a false option
              // - you lose a point for incorrectly not selecting a true option
              // - you get zero points for correctly not selecting a false option
              score = this.options.filter(option => option.is_correct === true && option.actual === 1).length
                      -
                      this.options.filter(option => option.is_correct === false && option.actual === 0).length
                      -
                      this.options.filter(option => option.is_correct === false && option.actual === 1).length
              break;
            default:
              break;
          }
          return score
        },
        scoreMessage() {
            return this.scoringType === 3 ? '' : `You have scored ${this.calculatedScore} out of a possible ${this.maxScore}`
        },
        canSubmit() {
          if (this.submitted === true) {
            return false
          }
          if (this.type === 'multiple') {
            return true
          }
          //Have all the true false options been answered?
          return this.options.filter(option => option.selected_response !== null).length === this.options.length
        },
    },
    methods: {
        initOption: function (option) {
          return  {
            ... option,
            selected_response: null,
            is_correct: false,
            actual: null,
            calculateActual: function () {
              return md5(`${this.text} 1`) === this.md ? 1: 0
            },
            calculateCorrect: function () {
              if (this.selected_response === 'dn') {
                return false
              }
              return (this.selected_response ?? 0) == this.actual
            },
            calculateResult: function () {
              this.actual = this.calculateActual()
              this.is_correct = this.calculateCorrect()
            },
            responseClass: function () {
              return this.is_correct ? 'bg-green-100' : 'bg-red-100'
            },
          }
        },
        onAnswerSelected: function (option, response) {
          option.selected_response = response
        },
        submitAnswers: function () {
          this.options.forEach(option => {
            option.calculateResult()
          })
          this.submitted = true;
          this.saveAnswer()
            .then(() => this.recordScore());

          this.$nextTick(() => {
            this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
            MathJax.typesetPromise();
          })
        },
        saveAnswer() {
          let submitUrl = this.public ? '/public/cals/' + this.calId + '/pages/' + this.pageId + '/submit' : '/cals/' + this.calId + '/pages/' + this.pageId + '/submit';

           return axios.post(submitUrl, {
            inputAnswer: this.options.map( (option => {
              return {
                question: option.text,
                answer: option.selected_response,
              };
            })),
            score: this.calculatedScore,
          })
        },
        recordScore() {
          axios.post('/scores/' + this.pageId, {
            score: this.calculatedScore,
            maxScore: this.maxScore,
          }).then(({ data }) => {
            this.calScorerElement.innerHTML = data.score;
        }).catch(e => { console.error(e) });
      },
    },
}

</script>

<style scoped>

 .option-group {
    margin-bottom: 20px;
    padding: 10px;
    min-height: 90px;
  }

</style>
