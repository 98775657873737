<template>
    <div class="action-button-wrapper">
        <button :id="id" @click="toggleDropDown" v-click-outside="closeDropDown"
                class="action-button bg-blue-600 hover:bg-blue-700 text-white font-bold hover:text-white hover:no-underline font-bold py-3 px-4 rounded">
            {{ buttonTitle }}
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
        </button>
        <div class="mt-2 py-2 bg-white rounded-lg shadow-xl absolute" v-show="toggle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    data () {
      return {
        toggle: false
      }
    },
    props: {
      id: {
        type: String,
        required: false
      },
      buttonTitle: {
        trype: String,
        required: false,
        default: 'Action'
      }
    },
    methods: {
      toggleDropDown() {
        this.toggle = !this.toggle
      },
      closeDropDown () {
        this.toggle = false
      },
    }
  }
</script>