<template>
  <div v-if="pages.length > 0">
    <div v-if="orphanPages.length > 0" class="border border-gray-200 py-3 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase">
        Unlinked pages
        <div class="text-xs normal-case">
          A user can not access these pages as no other page in the CAL links to them.
        </div>
    </div>
    <table v-if="orphanPages.length > 0" class="min-w-full leading-normal mb-5">
      <thead>
      <tr class="border border-gray-200">
        <th class="px-5 py-3 text-left bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Title
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          From/To links count
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
      </tr>
      </thead>
      <tbody class="border border-gray-200">
        <tr v-for="page in orphanPages" class="border-l border-r border-gray-200">
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-7/12">
            <p class="text-gray-900 whitespace-no-wrap">{{ page.title }}</p>
            <p class="bg-yellow-200 rounded-lg px-2 max-w-min ring-1 ring-yellow-400">{{ page.type.type }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            {{ page.page_links.length }}/{{ page.to_page_links.length }}
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            <a class="text-gray-700 hover:text-gray-700" :href="'/cals/' + calId + '/pages/' +  page.id" target="_blank">[Preview]</a>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            <a v-if="pages.length > 1" class="text-gray-700 hover:text-gray-700" :href="'/branching-page-links/' + page.id">[Edit&nbsp;Links]</a>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            <a class="text-gray-700 hover:text-gray-700" :href="'/pages/' +  page.id + '/edit'">[Edit&nbsp;Page]</a>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            <form :action="'/pages-branching/' + page.id" method="POST" :ref="'deleteForm' + page.id"
                  @submit.prevent="submitDeleteForm(page)">
              <input type="hidden" name="_method" value="delete">
              <input type="hidden" name="_token" :value="csrf">
              <button type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-600 inline-block" viewBox="0 0 20 20"
                     fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"/>
                </svg>
                <span class="sr-only">delete</span>
              </button>
            </form>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="linkedPages.length > 0" class="border border-gray-200 py-5 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase mt-10">
      Linked pages
    </div>
    <table v-if="linkedPages.length > 0" class="min-w-full leading-normal">
      <thead>
      <tr class="border border-gray-200">
        <th class="px-5 py-3 text-left bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Title
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          From/to links count
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
      </tr>
      </thead>
      <tbody class="border border-gray-200">
        <tr v-for="page in linkedPages" class="border-l border-r border-gray-200">
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-7/12">
            <p class="text-gray-900 whitespace-no-wrap">{{ page.title }}</p>
            <p class="bg-yellow-200 rounded-lg px-2 max-w-min ring-1 ring-yellow-400">{{ page.type.type }}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            {{ page.page_links.length }}/{{ page.to_page_links.length }} <p class="text-xs mt-2">{{ page.root === 1 ? 'start page' : ''}}</p>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            <a class="text-gray-700 hover:text-gray-700" :href="'/cals/' + calId + '/pages/' +  page.id" target="_blank">[Preview]</a>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            <a v-if="pages.length > 1" class="text-gray-700 hover:text-gray-700" :href="'/branching-page-links/' + page.id">[Edit&nbsp;Links]</a>
          </td>
          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-max">
            <a class="text-gray-700 hover:text-gray-700" :href="'/pages/' +  page.id + '/edit'">[Edit&nbsp;Page]</a>
          </td>
          <td class="px-5 py-5 border- border-b border-gray-200 bg-white text-sm">
            <form :action="'/pages-branching/' + page.id" method="POST" :ref="'deleteForm' + page.id"
                  @submit.prevent="submitDeleteForm(page)">
              <input type="hidden" name="_method" value="delete">
              <input type="hidden" name="_token" :value="csrf">
              <button type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-600 inline-block" viewBox="0 0 20 20"
                     fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"/>
                </svg>
                <span class="sr-only">delete</span>
              </button>
            </form>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  name: 'ManageBranchingPages',
  props: {
    pages: Array,
    calId: Number,
    csrf: String
  },
  data () {
    return {
    }
  },
  computed: {
    orphanPages(){
     return this.pages.filter(page => {
        return page.page_links.length === 0 && page.to_page_links.length === 0;
     })
    },
    linkedPages(){
     return this.pages.filter(page => {
        return page.page_links.length > 0 || page.to_page_links.length > 0;
     })
    }

  },
  methods: {
    submitDeleteForm (page) {
      const formId = 'deleteForm' + page.id
      const form = this.$refs[formId]

      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this page if you delete it',
        type: 'error',
        showCancelButton: true,
        dangerMode: true,
        cancelButtonClass: '#DD6B55',
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Delete!',
      }).then((result) => {
        if (result.value === true) {
          this.enabled = false
          form.submit()
        }
      })
    }
  }
}
</script>