<template>

    <div class="mt-4">

        <div v-if="showForm" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <!--
                Background overlay, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100"
                    To: "opacity-0"
                -->
                <div data-test="modal-background-overlay" @click="closeForm" name="modal-background-overlay" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:my-8 sm:max-w-2xl sm:w-full">
                    <div data-test="dismiss-modal" @click="closeForm" name="dismiss-modal" class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-grey-500 text-sm z-50">
                        <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                        <span class="sr-only text-sm">Close</span>
                    </div>

                    <div class="flash-success" role="alert">
                        <div class="flex">
                            <div class="py-1"><svg class="flash-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                            <div>
                                <p class="font-bold">Review CAL</p>
                                <p class="text-sm">I have reviewed the CAL content and confirm that the content is up to date and accurate.</p>
                            </div>
                        </div>
                    </div>              

                    <form id="cal-review-form" @submit.prevent="onSubmit" method="POST">
                        <div class="px-8 pt-2 pb-4">
                            <input type="submit" dusk="cal-reviewed" data-test="cal-reviewed" class="btn btn-edit" value="Confirm" />
                            <input @click="closeForm" type="button" dusk="close-btn" data-test="close-btn" class="mx-4 btn btn-info" value="Close" />
                        </div>            
                    </form>

                </div>
            </div>
        </div>

        <div class="pt-2 pb-4">
            <button name="review-cal" dusk="review-cal" data-test="review-cal" @click="showForm=true" class="p-2 btn btn-edit">Mark as Reviewed</button>
        </div>
        <div class="pt-2 pb-4 md:text-sm text-xs">
            Last review: <span id='cal-review-date'>{{reviewDate}}</span>
        </div>
    </div>

</template>

<script>

import axios from 'axios'

export default {
    props: {
        calId: {
            type: Number,
            required: true,
        },
        calReviewDate: {
            type: String,
            required: true,
        },
    },
    data: function() {
        return {
            showForm: false,
            reviewDate: this.calReviewDate,
        }
    },  
    methods: {
        async onSubmit () {    
            axios.put(`/cals/${this.calId}/review`)
                .then(() => {
                  this.onCalReviewed()
                  this.closeForm()
                })
                .catch( error => {
                  console.error(`${error.name}: ${error.message}`)
                })
        },        
        async onCalReviewed () {
            this.reviewDate = new Date().toLocaleDateString()
            axios.get(`/cals/review/count`)
                .then(({data}) => {
                    if (data.reviewCount === 0) {
                        document.getElementById('cal-review-list')?.remove()
                    } else {
                        document.getElementById('cal-review-list').querySelector('#cal-review-list-count').innerHTML = data.reviewCount
                    }
                })
                .catch( error => {
                  console.error(`${error.name}: ${error.message}`)
                })
        },
        closeForm () {
          this.showForm = false
        },      
    },          
}

</script>
