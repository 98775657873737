<template>

    <div>
        <div @click="() => showImg(src)" name="image-view" class="container w-48">
            <img class="object-contain " :src="src" :alt="alt" data-test="image"/>
        </div>

        <vue-easy-lightbox
            name="image-lightbox"
            data-test="lightbox"
            :move-disabled="true"
            :visible="visible"
            :imgs="imgs"
            @hide="handleHide"
        ></vue-easy-lightbox>
    </div>

</template>

<script>

import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    name: "ImageViewer",
    props: ['src', 'alt'],
    data() {
        return {
            visible: false,
            imgs: '',
        }
    },    
    methods: {
        showImg(src) {
            this.imgs = src            
            this.visible = true
        },
        handleHide() {
            this.visible = false
        }
    },
    components: {
        VueEasyLightbox
    },    
}

</script>