<template>
    <div ref="wholePage">
        <div class="mt-4" id="page" v-html="questionText"></div>
        <div class="mt-8 mb-2 bg-gray-100 p-5">
            <div v-if="tries < parseInt(maxTries) && !wordFeedback.correct">
                <p>Please type in your answer and press submit:</p>
                <input v-model="inputAnswer" id="freetxt_answer" type="text" name="freetxt_answer"
                       class="border-solid border-2 rounded w-full md:w-6/12 pl-2 h-9 mt-2"
                       data-test="freetxt-answer-input">
                <p v-if="!wordFeedback.correct && tries > 0" class="text-sm italic">
                <p class="my-2">Your response was: <strong>{{ userAnswer }}</strong></p>
                This is not the required answer. Please try again.
                </p>
                <br>
                <button
                    class="mt-5 inline-block py-2 px-5 border border-indigo-400 rounded focus:shadow-outline bg-gray-200 text-gray-900 font-semibold text-sm"
                    @click="submitAnswerFreeText" ref="freetextsubmitanswer" data-test="submit-freetxt-answer-button">
                    Submit
                </button>
                <div v-if="wordFeedback.score">
                    Score: {{ wordFeedback.score }} out of {{ maxScore }}
                </div>
            </div>
            <div class="my-5">
                <div v-if="wordFeedback.feedback !== ''">
                    <strong>Feedback</strong>
                    <p v-html="wordFeedback.feedback" data-test="matrix-word-feedback"></p>
                </div>
                <div v-if="tries === parseInt(maxTries) || wordFeedback.correct">
                    <p class="my-2"><strong>Your response was:</strong> {{ userAnswer }}</p>
                    <p class="my-2"><strong>Score:</strong> {{ wordFeedback.score }} out of {{ maxScore }}</p>
                    <strong>General feedback</strong>
                    <p v-html="generalFeedback" data-test="general-feedback"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'FreeTextSubmitAnswer',
    props: {
        pageId: {
            type: Number,
            required: true,
        },
        calId: {
            type: Number,
            required: true,
        },
        maxTries: {
            type: Number,
            required: true,
        },
        maxScore: {
            type: Number,
            required: true,
        },
        questionText: {
            type: String,
            required: true,
        },
        public: {
            type: Boolean,
            required: false,
        },
    },
    data () {
        return {
            inputAnswer: '',
            userAnswer: '',
            tries: 0,
            generalFeedback: '',
            wordFeedback: {
                feedback: '',
                correct: false,
                score: 0,
            },
            calScorerElement: null,
        }
    },
    mounted: function () {
        this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'))
        this.calScorerElement = document.getElementById('cal-score')

    },
    methods: {
        submitAnswerFreeText () {
            if (!this.isEmptyStringOrOnlyContainsSpaces(this.inputAnswer)) {
                let submitUrl = this.public ? '/public/cals/' + this.calId + '/pages/' + this.pageId + '/submit' : '/cals/' + this.calId + '/pages/' + this.pageId + '/submit';
                
                axios.post(submitUrl, {
                    inputAnswer: this.inputAnswer,
                    tries: this.tries,
                }).then(({ data }) => {
                    this.tries = parseInt(data.tries)
                    this.generalFeedback = data.generalFeedback
                    this.wordFeedback = data.wordFeedback
                    this.userAnswer = data.userAnswer
                    this.inputAnswer = ''

                    if (this.tries === parseInt(this.maxTries) || this.wordFeedback.correct) {
                        this.recordScore()
                    }

                    this.$nextTick(() => {
                        this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
                        MathJax.typesetPromise();
                    })

                })
            }
        },
        recordScore () {
            axios.post('/scores/' + this.pageId, {
                score: this.wordFeedback.score,
                maxScore: this.maxScore,
            }).then(({ data }) => {
                this.calScorerElement.innerHTML = data.score
            })
        },
        isEmptyStringOrOnlyContainsSpaces (str) {
            return str.replace(/\s/g, '').length === 0
        },
    },
}

</script>
