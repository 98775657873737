<template>
    <div class="pt-2 pb-4" v-if="full">
        <p v-html="content"></p>
    </div>
    <p class="text-gray-700 text-base mb-8" v-else v-html="content"></p>
</template>

<script>
export default {
    name: 'CalSummary',
    props: {
        content: String,
        full: Boolean
    }
}
</script>
