<template>
  <div v-if="groups.length > 0">
    <table class="min-w-full leading-normal">
      <thead>
      <tr class="border border-gray-200">
        <th class="px-5 py-3 text-left bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
          Title
        </th>
        <th class="px-5 py-3 text-left bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
          &nbsp;
        </th>
      </tr>
      </thead>
      <tbody class="border border-gray-200">
      <tr v-for="group in groups" class="border-l border-r border-gray-200">
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm w-11/12">
          <p class="text-gray-900 whitespace-no-wrap">{{ group.name }}</p>
        </td>
        <td class="px-5 py-5 border- border-b border-gray-200 bg-white text-sm">
          <form :action="'/groups/' + group.id" method="POST" :ref="'deleteForm' + group.id"
                @submit.prevent="submitDeleteForm(group)">
            <input type="hidden" name="_method" value="delete">
            <input type="hidden" name="_token" :value="csrf">
            <button type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-600 inline-block" viewBox="0 0 20 20"
                   fill="currentColor">
                <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"/>
              </svg>
              <span class="sr-only">delete</span>
            </button>
          </form>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'ManageGroups',
  props: {
    groups: Array,
    csrf: String
  },
  data () {
    return {
    }
  },
  methods: {
    submitDeleteForm (page) {
      const formId = 'deleteForm' + page.id
      const form = this.$refs[formId]

      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this group if you delete it',
        type: 'error',
        showCancelButton: true,
        dangerMode: true,
        cancelButtonClass: '#DD6B55',
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Delete!',
      }).then((result) => {
        if (result.value === true) {
          this.enabled = false
          form.submit()
        }
      })
    }
  }
}
</script>