<template>
  <div>
    <draggable
        v-model="list"
        @start="dragging = true"
        @end="dragging = false"
        item-key="title"
        @change="update"
        :disabled="!enabled"
    >
    <template #item="{ element }">
      <div class="page-link-item cursor-pointer flex" :class="{ 'not-draggable': !enabled }">
        <div class="mr-2">
          <img class="inline-block h-5"  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAA+klEQVRIie2WQQ6CMBBFX4wr9zZ6GhfsPK1nABMCykYX3MErqNEFbWggtLSF6oKfNCFDZ37bmfktLPhzCKCSQ8Qi3QI34CNHDexjk0Yjv0iiu0aqvkuXQCtH4hdNXhPNlkjb2zGWN9SOveC648nwM+L1gF0AJ+AJHAbmnDEfdQpsgCPwGLMYQVupxRiHAZS0VW8VGb1Pa2AXQNyNZezzK31x0Edm8M0svpU+2bW4vNvH5ut0PBY4x9KLy0kGO9DldfQNJmjynRvmFJb/uYwx+bW5SOasxCn9vKlnkKnHg6HLYPchECKvVpiePiHy6kUehVRB5XWWPl0wKb4bnGSKiKb4CgAAAABJRU5ErkJggg==" alt="drag"/>
        </div>
        <div class="flex-1">
          {{ element.link_page.title }}
        </div>
        <div>
          <form :action="'/page-links/' + element.id" method="POST" :ref="'deleteForm' + element.id" @submit.prevent="submitForm(element)">
            <input type="hidden" name="_method" value="delete">
            <input type="hidden" name="_token" :value="csrf">
            <button type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-600 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only">delete</span>
            </button>
          </form>
        </div>
      </div>
    </template>
  </draggable>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  name: 'ManageBranchingPageLinks',
  order: 0,
  components: {
    draggable
  },
  props: {
    pageLinkList: Array,
    pageId: Number,
    csrf: String
  },
  data () {
    return {
      list: this.pageLinkList,
      enabled: true,
      dragging: false,
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    }
  },
  methods: {
    async update () {
      this.enabled = false
      try {
        const response = await axios.post(
            '/page/' + this.pageId + '/order-pages',
            { pageLinkArray: this.list }
        )

        console.log(response.data);
        this.enabled = true
      } catch (err) {
        console.error(err)
      }
    },
    submitForm (element) {
      const formId = 'deleteForm' + element.id
      const form = this.$refs[formId]

      this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this page link if you delete it',
        type: 'error',
        showCancelButton: true,
        dangerMode: true,
        cancelButtonClass: '#DD6B55',
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Delete!',
      }).then((result) => {
        if (result.value === true) {
          this.enabled = false
          form.submit()
        }
      })
    }
  }
}
</script>
<style scoped>
  .not-draggable {
    cursor: no-drop;
  }
</style>