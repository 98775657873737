<template>
	<div class="p-2 grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-16">
	    <div v-for="answer in answers" class="min-h-[5rem] transition duration-500 ease-in-out rounded bg-white hover:bg-blue-100 bg-gray-100 transform hover:-translate-y-1 hover:scale-105 w-full lg:max-w-full lg:flex shadow" data-test="list-items">
            <div class="p-2">
                <div class="pt-1 font-light" data-test="answer-count-message">
                    Selected <span class="mx-1 px-2 py-1 text-xs font-bold border border-gray-600 rounded-full">{{ answer.answerCount}}</span> {{ pluralizeWord(answer.answerCount, 'time') }}
                    <p class="mt-2 font-light" data-test="score">Score: {{ answer.score }}</p>
                </div>
                <table class="mt-4">
                  <thead>
                      <tr>
                          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase">
                              <span>Options</span>
                          </th>
                          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase">
                              Answers
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="option in JSON.parse(answer.answer)" data-test="answer">
                          <td class="text-left px-5 py-5 border-b border-gray-200 bg-white text-sm align-top">
                              <span v-html="option.question"></span>
                          </td>
                          <td class="text-left px-5 py-5 border-b border-gray-200 bg-white text-sm align-top">
                              <span v-if="option.answer === 1">True</span>
                              <span v-else>-</span>
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
	    </div>
	</div>
</template>

<script>

export default {
    name: 'Mcq',
    props: {
        answers: {
            type: Object,
            required: true,
        },
    },
}
</script>