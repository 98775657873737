<template>

    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div @click="closeForm" name="modal-background-overlay" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:my-8 sm:max-w-2xl sm:w-full">
            <div @click="closeForm" name="dismiss-modal" class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-grey-500 text-sm z-50">
                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
                <span class="sr-only text-sm">Close</span>
            </div>

            <div v-if="!showError" class="flash-success" role="alert">
                <div class="flex">
                    <div class="py-1"><svg class="flash-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                        <p class="font-bold">No users matches found</p>
                        <p class="text-sm">You can either add a new user to the system or close to try another search.</p>
                        <p class="text-sm">Adding a new system user will also include them on the list.</p>
                        <p class="text-sm">Once you have completed editing the list, remember to update to persist the selection.</p>
                    </div>
                </div>
            </div>  

            <div v-if="showError" class="flash-error" role="alert">
                <div class="flex">
                    <div class="py-1"><svg class="flash-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                        <p class="font-bold">The User cannot be added for the following reasons:</p>
                        <p   v-for="(value, name) in errors" :key="name" class="text-sm">{{ value[0] }}</p>
                    </div>
                </div>
            </div>  

            <form id="add-user-form" @submit.prevent="onSubmit" method="POST">
                
                <div class="px-6 pt-4">
                    <p class="font-bold text-xl mb-2">Add User</p>
                </div>

                <div class="px-8 pt-2 pb-4">
                    <label for="uun" class="form-label">UUN</label>
                    <input name="uun" type="text" dusk="user-form-uun" data-test="user-form-uun" v-model="user.uun" class="form-input" placeholder="uun" required>                    
                </div>

                <div class="px-8 pt-2 pb-4">
                    <label for="email" class="form-label">Email</label>
                    <input name="email" type="email" dusk="user-form-email" data-test="user-form-email" v-model="user.email" class="form-input" placeholder="email" required>                    
                </div>

                <div class="px-8 pt-2 pb-4">
                    <label for="first_name" class="form-label">First Name</label>
                    <input name="first_name" type="text" dusk="user-form-first-name" data-test="user-form-first-name" v-model="user.first_name" class="form-input" placeholder="first name" required>                    
                </div>

                <div class="px-8 pt-2 pb-4">
                    <label for="last_name" class="form-label">Last Name</label>
                    <input name="last_name" type="text" dusk="user-form-last-name" data-test="user-form-last-name" v-model="user.last_name" class="form-input" placeholder="last name" required>                    
                </div>

                <div class="px-8 pt-2 pb-4">
                    <input :disabled="submitted" type="submit" dusk="create-btn" data-test="create-btn" class="btn btn-edit" value="Create" />
                    <input @click="closeForm" type="button" dusk="close-btn" data-test="close-btn" class=" mx-4 btn btn-info" value="Close" />
                </div>            
            </form>

        </div>
      </div>
    </div>

</template>

<script>

  import axios from 'axios';

  export default {
    name: 'ModalAddUserForm',
    data: function() {
        return {
            user: {
              id: '',
              uun: '',
              email: '',
              first_name: '',
              last_name: '',
            },
            errors: {},
            submitUri: '/users',
            submitted: false, 
            showError: false,           
        };
    },  
    methods: {
        async onSubmit () {    
            this.submitted = true;
            this.showError = false;
            await axios
                .post(
                  this.submitUri, 
                  this.user,  
                )
                .then(({data}) => {
                  if (data.status === 'success') {
                    this.user.id = data.id
                    this.$emit('itemAdded', this.user);
                    this.closeForm();
                  }
                  this.submitted = false;
                })
                .catch( error => {
                    switch (error.response?.status) {
                        case 422:  
                          this.handleValidation(error.response.data.errors)
                          break;
                        default:
                          console.error(`${error.name}: ${error.message}`);                                              
                    }
                    this.submitted = false;
                });  
        },
        handleValidation(validation_errors) {
          this.showError = true;
          this.errors = validation_errors;
        },
        closeForm () {
          this.user = {
              id: '',
              uun: '',
              email: '',
              first_name: '',
              last_name: '',
          };
          this.showError = false;
          this.errors = {},
          this.$emit('close');
        } 
    },
  };
</script>