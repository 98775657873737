<template>
    <div ref="wholePage">
        <div class="mt-4" id="page" v-html="questionText"></div>
        <div class="mt-8 mb-2 bg-gray-100 p-5">
            <div class="h-auto">
                <div v-if="userAnswer === ''" class="h-auto">
                    <p>Please type in your answer and press submit:</p>
                    <label for="essay_answer"></label>
                    <textarea v-model="inputAnswer" id="essay_answer" name="essay_answer" class="border-solid border-2 rounded w-full h-48 md:w-6/12 p-2 mt-2" data-test="essay-answer-input" @keyup="showErrorMessage = false" aria-label="Essay Answer"></textarea>
                    <br>
                    <button class="mt-5 py-2 px-5 border border-indigo-400 rounded focus:shadow-outline bg-gray-200 text-gray-900 font-semibold text-sm" @click="submitAnswerEssay" ref="essaysubmitanswer" data-test="submit-essay-answer-button">
                        Submit
                    </button>
                    <div class="inline text-red-600 italic ml-5" v-if="showErrorMessage">Please type your answer first</div>
                </div>
                <div v-else>
                    <div class="grid grid-cols-1 divide-y divide-grey-500">
                        <div>
                            <p class="font-bold">Your answer was:</p>
                            <div class="m-5" data-test="user-answer">
                                {{ userAnswer }}
                            </div>
                        </div>
                        <div>
                            <p class="font-bold mt-2">The suggested answer is:</p>
                            <div class="m-5 h-auto" v-html="modelAnswer" data-test="model-answer" id="model-answer"></div>
                        </div>
                        <div>
                            <p class="font-bold mt-2 mb-3">Compare your answer with the model answer then give yourself a score:</p>
                            <div v-for="score in parseInt(maxScore)" class="block md:inline p-4">
                                <input type="radio" id="essay_score" name="essay_score" :value="score"  class="cursor-pointer transform scale-150" @click="recordScore(score)" v-model="picked" data-test="self-scoring-radio">
                                <label for="essay_score" class="ml-2 text-lg">{{ score }}</label>
                            </div>
                            <span class="mr-5 hidden md:inline">|</span>
                            <hr class="md:hidden">
                            <input type="radio" name="essay_score" value="0" class="cursor-pointer transform scale-150 ml-4 mt-4 md:ml-0 md:mt-0" @click="recordScore(0)" v-model="picked" data-test="self-scoring-radio">
                            <label for="essay_score" class="ml-2 text-lg">0</label>
                            <div class="mt-5" v-if="picked" data-test="self-scoring-message">
                                You have awarded your answer <strong>{{ picked }}</strong> points out of {{ maxScore }}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "EssaySubmitAnswer",
    props: {
        pageId: {
            type: Number,
            required: true,
        },
        calId: {
            type: Number,
            required: true,
        },
        maxScore: {
            type: Number,
            required: true,
        },
        questionText: {
            type: String,
            required: true,
        },
        public: {
            type: Boolean,
            required: false,
        },
    },
    data() {
      return {
        inputAnswer: '',
        userAnswer: '',
        modelAnswer: '',
        picked: null,
        showErrorMessage: false,
        calScorerElement: null,
      }
    },
    mounted: function () {
      this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
      this.calScorerElement = document.getElementById('cal-score');
    },
    methods: {
      async submitAnswerEssay(e) {
        if (!this.isEmptyStringOrOnlyContainsSpaces(this.inputAnswer)) {
          
          let submitUrl = this.public ? '/public/cals/' + this.calId + '/pages/' + this.pageId + '/submit' : '/cals/' + this.calId + '/pages/' + this.pageId + '/submit';
          
          try{
            let response = await axios.post(submitUrl, {
              inputAnswer: this.inputAnswer,
              score: 0,
            });

            this.modelAnswer = response.data.modelAnswer;
            this.userAnswer = response.data.userAnswer;
          } catch (error) {
              console.error(error);
          }

        }
        else{
          this.showErrorMessage = true
        }

        this.$nextTick(() => {
          this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
          MathJax.typesetPromise();
        });

      },
      isEmptyStringOrOnlyContainsSpaces(str) {
        return str.replace(/\s/g, '').length === 0;
      },
      async recordScore(score) {

        // Update score to the user's selected value
        try{
          let submitUrl = this.public ? '/public/cals/' + this.calId + '/pages/' + this.pageId + '/submit' : '/cals/' + this.calId + '/pages/' + this.pageId + '/submit';

          await axios.post(submitUrl, {
            inputAnswer: this.inputAnswer,
            score: score,
          });
          
        } catch (error) {
            console.error(error);
        }

        // Save scores in the session
        try{
          let response = await axios.post('/scores/' + this.pageId, {
            score: score,
            maxScore: this.maxScore,
            userAnswer: this.userAnswer,
          });

          this.calScorerElement.innerHTML = response.data.score;
        } catch (error) {
           console.error(error);
        }
      },
    },
}

</script>

<style>
  input[type="radio"]:checked + label{
     color: #3490DC;
  }
</style>
