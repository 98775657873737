<template>
    
    <div class="rounded shadow p-6 m-4">
        <div class="mb-4">
            <h1 class="text-grey-darkest">{{listHeading}}</h1>
            <component :inputPlaceholder="inputPlaceholder" :searchUri="searchUri" :is="listAddComponent" @itemAdded="onItemAdded" @notFound="onNotFound" buttonInnerHtml="Select" />
        </div>
        <component :is="newItemComponent" v-if="showNewItem" @itemAdded="onItemAdded" @close="showNewItem=false" />
        <div>
            <div :dusk="`${submitKey}_${item.id}`" class="flex mb-4 items-center" :key="item.id" v-for="(item, index) in data">
                <input type="hidden" :id="submitKey" :name="`${submitKey}[]`" :value="item.id">
                <p class="w-full text-grey-darkest">{{item[displayKey]}}</p>
            </div>
            <p>
                <strong>A CAL has to have an owner.</strong>
                <br>You are this CAL owner and only you can change the owner by providing another account.
                <br>If you change the CAL owner you will no longer be able to edit/access the CAL unless you are also set as the CAL editor/administrator or can access it as a subject area lead.
                <p class="mt-5">Please confirm the change by pressing the <strong>"Set New Owner"</strong> button.</p>
            </p>
        </div>
    </div>

</template>

<script>

import ListAddRequest from './ListAddRequest';
import ModalAddUserForm from './ModalAddUserForm';

export default {
    props: [
        'listHeading',
        'items',
        'displayKey',
        'inputPlaceholder',
        'searchUri',
        'submitKey'
    ],
    data: function() {
        return {
            data: this.items,
            showNewItem: false,
        };
    },
    computed: {
        listAddComponent() {
            return ListAddRequest;
        },
        newItemComponent() {
            return ModalAddUserForm;
        },
    },
    methods: {
        onNotFound: function() {
            this.showNewItem = true;
        },
        onItemAdded: function(item) {
            this.data = [];
            this.data.push(item);
        },
        removeItem: function(index)  {
            this.data.splice(index, 1);
        },
    }, 
}
</script>