<template>
  <div :id="'tags-cal-' + calId" v-if="show">
      <label for="tags" class="sr-only">Add subject area tag</label>
      <vue-tags-input
          v-model="tag"
          :tags="tags"
          :autocomplete-items="autocompleteItems"
          :add-only-from-autocomplete="true"
          :autocomplete-min-length="0"
          :delete-on-backspace="false"
          @focus="initItems"
          @tags-changed="update"
          id="tags"
      />
      <button @click="saveTags()" id="save-tags" class="btn btn-edit mt-2">
        Save Subject Area Tags
      </button>
      <div v-if="showSaveAlert" class="inline-block ml-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="inline-block text-green-500 0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        saved
      </div>
      <div v-if="showSaveError" class="mt-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <span class="block sm:inline">Something went wrong.</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3" @click="showSaveError = false">
          <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
  </div>
</template>

<script>
import VueTagsInput from '@sipec/vue3-tags-input';
import axios from 'axios';

export default {
  name: 'TagsInput',
  components: {
    VueTagsInput,
  },
  props: {
    calId: Number
  },
  data() {
    return {
      show: false,
      success: false,
      oldTag: '',
      tag: '',
      tags: [],
      allTags: [],
      autocompleteItems: [],
      showSaveAlert: false,
      showSaveError: false,
    };
  },
  watch: {
    'tag': 'initItems',
  },
  methods: {
    update(newTags) {
      this.tags = newTags
      this.autocompleteItems = []
    },
    initItems() {
      this.reSynchTag()

      if (this.isRepeatNonEmptySearch()) {
        return
      }

      this.oldTag = this.tag

      if (this.isTagEmpty()) {
        this.autocompleteItems = this.allTags
      } else {
        this.autocompleteItems = this.allTags.filter(item => {
          return item.text.toLowerCase().indexOf(this.tag.toLowerCase().trim()) !== -1;
        });
      }
    },
    reSynchTag() {
      if(document.querySelector('#tags-cal-' + this.calId + ' .ti-new-tag-input').value.trim() === '' && this.tag.length > 0) {
        this.tag = ''
      }
    },
    isTagEmpty() {
      return this.tag.trim() === ''
    },
    isRepeatNonEmptySearch() {
      return this.tag === this.oldTag && !this.isTagEmpty()
    },
    loadAllTags() {
      const url = `/subject-areas/tags`;
      axios.get(url).then(response => {
          this.allTags = response.data.map(subjectArea => ({ text: subjectArea }))
      }).catch(() => console.warn('Oh. Something went wrong'));
    },
    loadExistingTags() {
      const url = `/subject-areas/tags/cal/${this.calId}`
      axios.get(url).then(response => {
        this.show = true
        this.tags = response.data.map(subjectArea => ({ text: subjectArea }))
      }).catch(() => console.warn('Oh. Something went wrong'));
    },
    saveTags() {
      let postTags = this.tags.map(tagObject => {
        return tagObject.text
      })
      axios.post(`/subject-areas/tags/cal/${this.calId}`, {
        tags: postTags,
      }).then((response) => this.flashSaveMessage())
          .catch(() => this.showSaveError = true);
    },
    flashSaveMessage () {
      this.showSaveError = false
      this.showSaveAlert = true
      setTimeout(() =>
          this.showSaveAlert = false, 2000
      )
    },
  },
  mounted () {
    this.loadExistingTags()
    this.loadAllTags()
  }
};
</script>