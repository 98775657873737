<template>

    <fieldset class="flex option-response-group mt-5 md:mt-0">
      <legend class="sr-only assistive-text">Please select your response for option {{ option.index }}</legend>
      <div class="option-response-item">
        <input :data-test="`radio-true-${option.index}`" type="radio" :id="`radio-true-${option.index}`" :name="`response-${option.index}`" value="1" @change="$emit('answerSelected', option, $event.currentTarget.value)">
        <label class="mx-4" :for="`radio-true-${option.index}`">True</label><br>
      </div>          
      <div class="option-response-item">  
        <input :data-test="`radio-false-${option.index}`" type="radio" :id="`radio-false-${option.index}`" :name="`response-${option.index}`" value="0" @change="$emit('answerSelected', option, $event.currentTarget.value)">
        <label class="mx-4" :for="`radio-false-${option.index}`">False</label><br>
      </div> 
      <div class="option-response-item">
        <input :data-test="`radio-dn-${option.index}`" type="radio" :id="`radio-dn-${option.index}`" :name="`response-${option.index}`" value="dn" @change="$emit('answerSelected', option, $event.currentTarget.value)">
        <label class="ml-2 md:mx-4" :for="`radio-dn-${option.index}`">Don't Know</label>
      </div>
    </fieldset>

</template>

<script>

export default {
  name: "McqTrueFalseAnswer",
  props: {
    option: {
      type: Object,
      required: true,
    },
  },    
}

</script>
