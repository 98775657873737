<template>
  <div>
    <div v-if="showRadioButton" class="md:flex">
      <div class="flex-1"></div>
      <div class="flex mt-4 md:mt-0">
        <SwitchGroup>
          <SwitchLabel class="mr-4">Owned CALs</SwitchLabel>
          <Switch
            v-model="enabled"
            :class="enabled ? 'bg-blue-700' : 'bg-gray-300'"
            class="relative inline-flex items-center h-6 rounded-full w-11"
            @toggle="toggleProperty"
            data-test="set-property-switch"
          >
            <span class="sr-only">Owned CALs</span>
            <span
              :class="enabled ? 'translate-x-6' : 'translate-x-1'"
              class="
                inline-block
                w-4
                h-4
                transform
                bg-white
                rounded-full
                transition
                duration-200
                ease-in-out
              "
            />
          </Switch>
        </SwitchGroup>
      </div>
    </div>
    <div v-show="!enabled">
      <slot name="cals"></slot>
    </div>
    <div v-show="enabled">
      <slot name="owned-cals"></slot>
    </div>
  </div>
</template>

<script>
import {  Switch,  SwitchGroup,  SwitchLabel,  SwitchDescription,} from "@headlessui/vue";

export default {
  name: "CalsPage",
  components: { Switch, SwitchGroup, SwitchLabel, SwitchDescription },
  props: {
    showRadioButton: Boolean
  },  
  data() {
    return {
      enabled: false,
    };
  },
  methods: {
    toggleProperty() {
      this.enabled = !this.enabled;
    }
  }
};
</script>