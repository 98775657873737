<template>
    
    <div class="rounded shadow p-6 m-4">
        <div class="mb-4">
            <h1 class="text-grey-darkest">{{listHeading}}</h1>
            <component :inputPlaceholder="inputPlaceholder" :searchUri="searchUri" :is="listAddComponent" @itemAdded="onItemAdded" @notFound="onNotFound" buttonInnerHtml="Select" />
        </div>
        <component :is="newItemComponent" v-if="showNewItem" @itemAdded="onItemAdded" @close="showNewItem=false" />
        <div>
            <div :dusk="`${submitKey}_${item.id}`"  data-test="list-item" class="flex mb-4 items-center" :key="item.id" v-for="(item, index) in data">
                <input type="hidden" :id="submitKey" :name="`${submitKey}[]`" :value="item.id">
                <p class="w-full text-grey-darkest">{{item[displayKey]}}</p>
                <button name="remove-item" data-test="remove-item" @click="removeItem(index)" class="p-2 btn btn-delete">Remove</button>
            </div>
        </div>
        <p class="mt-5">Please remember to press the <strong>Update</strong> button to save the editors.</p>
    </div>

</template>

<script>

import ListAddRequest from './ListAddRequest';
import ModalAddUserForm from './ModalAddUserForm';

export default {
    props: [
        'listHeading',
        'items',
        'displayKey',
        'inputPlaceholder',
        'searchUri',
        'submitKey'
    ],
    data: function() {
        return {
            data: this.items,
            showNewItem: false,
        };
    },
    computed: {
        listAddComponent() {
            return ListAddRequest;
        },
        newItemComponent() {
            return ModalAddUserForm;
        },
    },
    methods: {
        onNotFound: function() {
            this.showNewItem = true;
        },
        onItemAdded: function(item) {
            this.data.push(item);
        },
        removeItem: function(index)  {
            this.data.splice(index, 1);
        },
    }, 
}
</script>