<template>
    <div ref="wholePage">
        <div class="mt-4" id="page" v-html="questionText"></div>
        <span class="sr-only assistive-text">
            Calculate the correct answer based on the question and type your answer into the box.
            The values will change each time you refresh/reload the page.
        </span>
        <div class="mt-8 mb-2 bg-gray-100 p-5">
            <!-- hint box -->
            <div class="my-2 text-blue-600" v-if="hintText">
                <span @click="toggleHint" class="cursor-pointer" id="hint_link">
                  <svg xmlns="http://www.w3.org/2000/svg" class="transform -translate-y-px h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <g><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></g>
                  </svg>
                  Hint
                </span>
            </div>
            <div class="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 mb-4 shadow-md" v-if="!hintIsHidden" id="hint" v-html="hintText" role="alert"></div>
            <!-- end -->

            <!-- input box and submit button -->
            <div v-if="generalFeedback === ''">
                <p>
                  Please type in your answer and press submit:
                </p>
                <input v-model="inputAnswer" id="maths_answer" type="text" name="maths_answer" class="border-solid border-2 rounded w-9/12 md:w-3/12 pl-2 h-9 mt-2" data-test="maths-answer-input">
                <span v-html="units" class="ml-2"></span>

                <div>
                    <button class="mt-5 py-2 px-5 border border-indigo-400 rounded focus:shadow-outline bg-gray-200 text-gray-900 font-semibold text-sm" @click="submitAnswerMaths" ref="mathssubmitanswer" data-test="submit-maths-answer-button">
                        Submit
                    </button>
                    <span v-if="nan_error" class="ml-4 text-red-900" id="nan_error">Please input numbers only</span>
                </div>
            </div>
            <!-- end -->

            <!-- Users's response, score and correct answer -->
            <div class="my-2" v-if="step > 0">
                <p class="bg-yellow-100 border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 mb-4 shadow-md" data-test="user-response">
                    Your response was <strong>{{ userAnswer }}</strong> {{ units }}.
                </p>
                <p v-if="generalFeedback !== ''" class="border-t-4 rounded-b px-4 py-3 mb-4 shadow-md" :class="feedbackMode">
                <p class="my-2 font-bold">Score</p>
                <p v-if="result !== false">
                    You have scored <strong>{{ result.score }}</strong> out of {{ maxScore }}.
                </p>
                <p v-else>
                    You have scored <strong>0</strong> out of {{ maxScore }}.
                </p>
                <p v-if="correctAnswer !== ''" data-test="correct-answer">
                    The correct answer is <strong>{{ correctAnswer }}</strong>
                </p>
                </p>
            </div>
            <!-- end -->

            <!-- step feedback -->
            <div v-if="step > 0 && stepFeedback !== '' && (result === false || result.isCorrect === false)" class="my-2 not-italic question-feedback-text" role="alert">
                <p class="my-2 font-bold">Feedback</p>
                <p v-html="stepFeedback" data-test="step-feedback"></p>
            </div>
            <!-- end -->

            <!-- incorrect answer additional feedback -->
            <div v-if="result.isCorrect === false">
                <div class="my-2 question-feedback-text" role="alert">
                    <p class="my-2 font-bold">Additional feedback</p>
                    <div v-html="result.feedback" data-test="additional-feedback"></div>
                </div>
            </div>
            <!-- end -->

            <!-- Correct answer feedback -->
            <div v-if="result.isCorrect === true">
                <div class="my-2 question-feedback-text" role="alert">
                    <p class="my-2 font-bold">Feedback</p>
                    <div v-html="result.feedback" data-test="correct-answer-feedback"></div>
                </div>
            </div>
            <!-- end -->

            <!-- general feedback -->
            <div v-if="generalFeedback !== ''" class="my-2 question-feedback-text" role="alert">
                <p class="my-2 font-bold">General feedback</p>
                <div v-html="generalFeedback" data-test="general-feedback"></div>
            </div>
            <!-- end -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "MathsSubmitAnswer",
    props: {
        pageId: {
            type: Number,
            required: true,
        },
        calId: {
            type: Number,
            required: true,
        },
        hintText: {
            type: String,
            required: true,
        },
        mathValues: {
            type: String,
            required: true,
        },
        units: {
            type: String,
            required: true,
        },
        maxScore: {
            type: Number,
            required: true,
        },
        questionText: {
            type: String,
            required: true,
        },
        public: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
      feedbackMode() {
        return this.result.isCorrect === true ? 'bg-green-100 border-green-500 text-green-900' : 'bg-red-100 border-red-500 text-red-900';
      }
    },
    data() {
      return {
        hintIsHidden: true,
        inputAnswer: '',
        userAnswer: '',
        step: 0,
        stepFeedback: '',
        generalFeedback: '',
        correctAnswer: '',
        result: false,
        nan_error: false,
        calScorerElement: null,
      }
    },
    mounted: function () {
      this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
      this.calScorerElement = document.getElementById('cal-score');
    },
    methods: {
      submitAnswerMaths(e) {
        if (!this.isNotValidNumber(this.inputAnswer)) {
          let submitUrl = this.public ? '/public/cals/' + this.calId + '/pages/' + this.pageId + '/submit' : '/cals/' + this.calId + '/pages/' + this.pageId + '/submit';

          axios.post(submitUrl, {
            inputAnswer: this.inputAnswer,
            step : this.step,
            mathValues: this.mathValues
          }).then(response => {
            this.step = response.data.step;
            this.stepFeedback = response.data.stepFeedback;
            this.userAnswer = response.data.userAnswer;
            this.inputAnswer = '';
            this.generalFeedback = response.data.generalFeedback;
            this.correctAnswer = response.data.correctAnswer;
            this.result = JSON.parse(response.data.result);

            if (this.step > 0 && this.generalFeedback !== '') {
              this.recordScore()
            }

            this.$nextTick(() => {
              this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
              MathJax.typesetPromise();
            })
          });
        }
      },
      toggleHint(e) {
        this.hintIsHidden = !this.hintIsHidden;
        this.$nextTick(() => {
          MathJax.typesetPromise();
        })
      },
      isNotValidNumber(str) {
        return this.nan_error = isNaN(str) || str.replace(/\s/g, '').length === 0
      },
      recordScore() {
        axios.post('/scores/' + this.pageId, {
          score: this.result.score === undefined ? 0: this.result.score,
          maxScore: this.maxScore,
        }).then(({ data }) => {
          this.calScorerElement.innerHTML = data.score;
        });
      },
    },
}

</script>
