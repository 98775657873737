<template>
    
    <div dusk="list-add-request" class="flex mt-4">      
        <input :aria-label="inputPlaceholder" dusk="search-value" data-test="search-value" v-model="searchValue" class="shadow appearance-none border rounded w-full py-2 px-3 mr-4 text-grey-darker" :placeholder="inputPlaceholder">
        <button dusk="add-new-item" data-test="add-new-item" class="p-2 btn btn-edit" :disabled="!searchValue.length" @click="addItem">{{ buttonInnerHtml }}</button>
    </div>       

</template>

<script>

import axios from 'axios';

export default {
    props: {
      inputPlaceholder: {
        type: String,
        required: true
      },
      searchUri: {
        type: String,
        required: true,
      },
      buttonInnerHtml: {
        type: String,
        required: true,
        default: 'Add'
      }
    },
    data: function() {
        return {
            searchValue: '',
        };
    },
    methods: {
        async addItem (event) {
            event.preventDefault();
            await axios
                .get(this.searchUri ?? '', {
                    params: {
                        searchValue: this.searchValue,
                    }
                })
                .then(({ data: item }) => {
                    this.$emit('itemAdded', item);
                    this.searchValue = "";
                })
                .catch( error => {
                    switch (error.response?.status) {
                        case 404:
                            this.$emit('notFound', this.searchValue);
                            this.searchValue = "";
                            break;
                        default:
                            throw error;
                    }
                });  
        },
    }, 
}
</script>