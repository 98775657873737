<template>
  <div v-if="groups.length === 0">
    <p>There are no groups on the system.</p>
  </div>
  <div v-else>
    <div class="px-10">
      <label class="mr-2" for="group-search">Filter:</label>
      <input id="group-search" class="border-solid border-2 rounded px-2 h-9 mt-2" v-model="search" placeholder="search term ...">
    </div>
    <div class="p-10 grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6">
      <a v-for="group in filteredItems" key="group.id" :href="'/group/' + group.id + '/cals'" class="subject-area-link">
        <div class="p-4 flex flex-col justify-between leading-normal">
          <div>
            <div class="text-gray-900 font-bold text-xl">{{ group.name }}</div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Groups',
  props: {
    groups: Array
  },
  data() {
    return {
      search: ''
    };
  },
  computed: {
    filteredItems() {
      return this.groups.filter( ({ name }) => {
        return name.toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1;
      });
    },
  },
};
</script>