<template>
    <div class="whitespace-nowrap pl-4">
        <SwitchGroup>
            <SwitchLabel class="mr-4">{{ labelText }}</SwitchLabel>
            <Switch
                v-model="enabled"
                :class="enabled ? this.color : 'bg-gray-300'"
                class="relative inline-flex items-center h-6 rounded-full w-11"
                @click="toggleProperty"
                @keyup.space="toggleProperty"
                data-test="set-property-switch"
            >
                <span class="sr-only">{{ labelText }}</span>
                <span
                    :class="enabled ? 'translate-x-6' : 'translate-x-1'"
                    class="inline-block w-4 h-4 transform bg-white rounded-full transition duration-200 ease-in-out"
                />
            </Switch>
        </SwitchGroup>
    </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { Switch, SwitchGroup, SwitchLabel, SwitchDescription } from '@headlessui/vue';

export default {
    name: 'PropertySwitch',
    components: { Switch, SwitchGroup, SwitchLabel, SwitchDescription },
    props: {
        url: String,
        flag: Boolean,
        labelText: String,
        color: {
          default: 'bg-blue-700',
          type: String
        }
    },

    setup(props) {
        const enabled = ref(props.flag);

        return { enabled }
    },

    methods: {
        async toggleProperty(e) {
            try {
                let response = await axios.post(this.url);

                this.enabled = response.data.flag;
            } catch (error) {
                this.enabled = !this.enabled;
            }
        }
    }
}
</script>
