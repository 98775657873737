import { createApp } from 'vue';
import PageQuestionPairs from './components/pages/Pairs';
import McqQuestion from './components/pages/Mcq';
import ImageViewer from './components/ImageViewer';
import ListEdit from './components/ListEdit';
import Search from './components/Search';
import Essay from './components/pages/Essay';
import Free from './components/pages/Free';
import Info from './components/pages/Info';
import TagsInput from './components/TagsInput';
import GroupTagsInput from './components/GroupTagsInput';
import Maths from './components/pages/Maths';
import SubjectAreas from './components/SubjectAreas';
import Groups from './components/Groups';
import PropertySwitch from './components/PropertySwitch';
import CalReview from './components/CalReview';
import CalSummary from './components/CalSummary';
import ManageLinearPages from './components/ManageLinearPages'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import AnswersReport from './components/AnswersReport';
import UserCalAnswersReport from './components/UserCalAnswersReport';
import GlobalMethodsPlugin from './plugins/GlobalMethodsPlugin';
import ManageBranchingPages from './components/ManageBranchingPages'
import OwnerEdit from './components/OwnerEdit';
import ManageGroups from './components/ManageGroups'
import ManageBranchingPageLinks from './components/ManageBranchingPageLinks'
import AddPage from './components/AddPage';
import ActionButton from './components/ActionButton';
import vClickOutside from "click-outside-vue3"
import axios from 'axios'
import CalsPage from './components/CalsPage';

const app = createApp({
  el: '#app',
  data() {
    return {
      sidebarOpen: false,
      dropdownOpen: false,
      pathwayVisible: false,
      objectivesVisible: false,
      infoVisible: false,
      kbVisible: false,
      ddxVisible: false,
    }
  },
  components: {
    PageQuestionPairs,
    ImageViewer,
    ListEdit,
    Search,
    Essay,
    Free,
    Info,
    TagsInput,
    GroupTagsInput,
    Maths,
    McqQuestion,
    SubjectAreas,
    Groups,
    PropertySwitch,
    CalReview,
    CalSummary,
    ManageLinearPages,
    ManageBranchingPages,
    AnswersReport,
    UserCalAnswersReport,
    OwnerEdit,
    ManageGroups,
    ManageBranchingPageLinks,
    AddPage,
    ActionButton,
    CalsPage,
  },
  methods: {
    calSearch(e) {
      this.$refs.search.calSearch(e);
    },
    getSweetAlertDeletePopUp() {
      return this.$swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this item if you delete it',
        type: 'error',
        showCancelButton: true,
        dangerMode: true,
        cancelButtonClass: '#DD6B55',
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Delete!',
      })
    },
    async submitFormAfterCheck(formToSubmit) {
      const result = await this.getSweetAlertDeletePopUp()

      if (result.value === true) {
        document.getElementById(formToSubmit).submit()
      }
    },
    async removeLegacyInfo(pageId) {
      const result = await this.getSweetAlertDeletePopUp()

      if (result.value === true) {
        try {
          await axios.post('/pages/' + pageId + '/remove-info')

          document.getElementById('info').value = ''
          document.getElementById('info-display').style.display = 'none'
        } catch (err) {
          console.error(err)
        }
      }
    },
    async removeLegacyDifferentialDiagnosis(differentialDiagnosisId) {
      const result = await this.getSweetAlertDeletePopUp()

      if (result.value === true) {
        try {
          await axios.delete('/delete-differential-diagnosis/' + differentialDiagnosisId)

          document.getElementById('ddx-display').style.display = 'none'
        } catch (err) {
          console.error(err)
        }
      }
    },
    async removeResource(resourceId) {
      const result = await this.getSweetAlertDeletePopUp()

      if (result.value === true) {
        try {
          await axios.delete('/delete-resource/' + resourceId)

          document.getElementById('resource-display').style.display = 'none'
        } catch (err) {
          console.error(err)
        }
      }
    },
    async removeLegacyKnowledgeBaseLinks(pageId) {
      const result = await this.getSweetAlertDeletePopUp()

      if (result.value === true) {
        try {
          await axios.post('/pages/' + pageId + '/remove-knowledge-base-links')

          document.getElementById('knowledge-base-links-display').style.display = 'none'
        } catch (err) {
          console.error(err)
        }
      }
    },
    async removeLegacyLearningObjectives(calId) {
      const result = await this.getSweetAlertDeletePopUp()

      if (result.value === true) {
        try {
          await axios.post('/cals/' + calId + '/remove-learning-objectives')

          document.getElementById('learning-objectives-display').style.display = 'none'
        } catch (err) {
          console.error(err)
        }
      }
    },
    async publishCalAndEditPage(calId, pageId) {
      const result = await this.$swal({
        title: 'Unpublish Cal',
        html: '<p>You can only edit pages if a cal is unpublished. Do you want to unpublish the cal and edit this page?</p><p class="mt-2">Note you should remember to set the status back to published once you have finished editing.</p>',
        type: 'error',
        showCancelButton: true,
        dangerMode: true,
        cancelButtonClass: '#DD6B55',
        confirmButtonColor: '#006400',
        confirmButtonText: 'Unpublish and edit',
      })

      if (result.value === true) {
        const url = '/cals/' + calId + '/publish'

        try {
          let response = await axios.post(url);

          document.location = '/pages/' + pageId + '/edit?mode=frontedit'
        } catch (error) {
          this.$swal({
            title: 'Oops ...',
            html: '<p>Something went wrong. We could not unpublish your cal. Please try again or contact support.</p>',
            icon: 'error'
          })
        }
      }
    },
  },
})
.use(VueSweetalert2)
.use(GlobalMethodsPlugin)
.use(vClickOutside)
app.mount('#app');
