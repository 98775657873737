<template>
    <action-button button-title="Add Page" v-if="parseInt(cal.type_id) === 1">
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/1'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Info Page
        </a>
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/4'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Maths Page
        </a>
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/5'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Essay Page
        </a>
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/3'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Free Text Page
        </a>
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/2'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Pair Match Page
        </a>
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/6'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            True False Page
        </a>
        <a :href="'/cals/' + cal.id + '/pages/create/pagetype/7'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            MCQ Page
        </a>
    </action-button>
    <action-button button-title="Add Page" v-if="parseInt(cal.type_id) === 2">
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/1'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Info Page
        </a>
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/4'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Maths Page
        </a>
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/5'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Essay Page
        </a>
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/3'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Free Text Page
        </a>
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/2'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            Pair Match Page
        </a>
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/6'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
            True False Page
        </a>
        <a :href="'/cals/' + cal.id + '/manage-pages-branching/create/pagetype/7'" class="block px-4 py-2 text-black hover:bg-gray-300 hover:text-black cursor-pointer">
          MCQ Page
        </a>
    </action-button>
</template>

<script>
  import ActionButton from './ActionButton'

  export default {
    props: {
      cal: {
        type: Object,
        required: true
      },
    },
    components: {
      ActionButton
    },
  }
</script>