<template>
  <div class="mt-8 w-full h-auto" id="page" ref="wholePage" v-html="content"></div>
</template>

<script>

export default {
    name: "Info",
    props: ['content'],
    mounted: function () {
      this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
    },
}

</script>