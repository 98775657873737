<template>
    <div v-if="pageAnswers.length == 0" class="shadow-none rounded-none" data-test="no-answers-message">
        There has been no answers by that user yet.
    </div>
    <div v-else class="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal" data-test="report-table">
            <thead>
                <tr>
                    <th class="px-5 py-3 border-b-2 border-gray-200 border bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase">
                        Page&nbsp;ID
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 border bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-1/2 lg:w-1/5">
                        Page&nbsp;Title
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 border bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-1/2 lg:w-4/5">
                        Answer
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="page in pageAnswers">
                    <td class="text-left px-5 py-5 border-b border-gray-200 bg-white text-sm align-top">
                        {{ page.pageId }}
                    </td>
                    <td class="text-left px-5 py-5 border-b border-gray-200 bg-white border text-sm align-top">
                        <p class="mb-5">
                            <a :href="'/cals/' + calId + '/pages/' + page.pageId" target="_blank">{{ page.pageTitle }}</a>
                        </p>
                        <div v-for="scores in page.scoreDistribution">
                            <p>Score: {{ scores.score }}, distribution: {{ scores.percentage }}%</p>
                        </div>
                    </td>
                    <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm border text-left align-top">
                        <component :answers="page.answers" :is="page.pageType" data-test="child-component" />
                    </td>
                </tr>
            </tbody>
         </table>
    </div>
</template>

<script>
import axios from 'axios'
import Pair from './reports/Pair'
import Free from './reports/Free'
import Maths from './reports/Maths'
import Essay from './reports/Essay'
import Multipletruefalse from './reports/MultipleTrueFalse'
import Mcq from './reports/Mcq'

export default {
    name: 'UserCalAnswersReport',
    props: {
        calId: {
            type: Number,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
    },
    data() {
      return {
        pageAnswers: {},
      }
    },
    components: {
        Pair,
        Free,
        Maths,
        Essay,
        Multipletruefalse,
        Mcq,
    },
    mounted() {
     this.getAnswersReport();
    },
    methods: {
        async getAnswersReport() {
            try{
                let response = await axios.get('/get-user-cal-answers-report/' + this.calId + '/' + this.userId);
                return this.pageAnswers = response.data.pageAnswers;
            } catch (error) {
                // console.log("error", error);
            }
        },
    },
}
</script>
