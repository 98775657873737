<template>
  <div class="border border-gray-200 px-4 py-2 mt-4 mb-8 rounded" ref="wholePage">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block float-right mt-1 text-gray-700" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
    </svg>
    <h2 class="mt-1 inline-block">Question</h2>
    <div class="mt-4" id="page" v-html="questionText"></div>
      <span class="sr-only assistive-text">
          There are two columns. The first column contains the questions. The second column contains a list of answers.
          Move an answer to the question it matches. Do this for all questions.
          An answer can be moved back to the answer bank. If you replace an answer the original answer is returned to the answer bank.
      </span>

      <div class="mt-8 mb-2 p-5">

        <div v-if="submitted === true" class="question-feedback-text" role="alert">
          <div class="flex">
            <div class="py-1">
                <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
            </div>
            <div>
                <p v-html="feedbackText"></p>
                <p>You have scored {{ questions.filter(question => question.isCorrect()).length }} out of a possible {{ questions.length }}</p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-2">
          <div>
            <h1>Questions</h1>
            <div v-for='(question) in questions' :key="question.id" data-test='question' v-bind:class="[submitted ? question.responseClass() : '']" class='bg-gray-200 rounded-lg drop-zone'
                @drop='answerQuestionEvent($event, question)' @dragenter.prevent @dragover.prevent
            >
              <div class='question pair-item' :class="{ answered: question.answer !== null }">
                <span v-html="question.text"></span>
              </div>
              <div v-if="question.answer !== null">
                <div :draggable="submitted === false" @dragstart='startDrag($event, question.answer)' data-test='answer' v-bind:class="[submitted ? '' : 'draggable']" class='pair-item answer'>
                  <div v-html="question.answer.text"></div>
                  <div class="relative" v-if="submitted === false" >
                    <button :data-test='`answer-list-${question.answer.id}`' @click="question.answer.showdd = !question.answer.showdd" class="relative z-10 block rounded px-2 focus:outline-none" aria-haspopup="true">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAA+klEQVRIie2WQQ6CMBBFX4wr9zZ6GhfsPK1nABMCykYX3MErqNEFbWggtLSF6oKfNCFDZ37bmfktLPhzCKCSQ8Qi3QI34CNHDexjk0Yjv0iiu0aqvkuXQCtH4hdNXhPNlkjb2zGWN9SOveC648nwM+L1gF0AJ+AJHAbmnDEfdQpsgCPwGLMYQVupxRiHAZS0VW8VGb1Pa2AXQNyNZezzK31x0Edm8M0svpU+2bW4vNvH5ut0PBY4x9KLy0kGO9DldfQNJmjynRvmFJb/uYwx+bW5SOasxCn9vKlnkKnHg6HLYPchECKvVpiePiHy6kUehVRB5XWWPl0wKb4bnGSKiKb4CgAAAABJRU5ErkJggg=="/>
                      <span class="sr-only assistive-text">Remove Answer: {{ question.answer.text }}</span>
                    </button>

                    <div v-if="question.answer.showdd" @click="question.answer.showdd = false" class="fixed inset-0 h-full w-full z-10"></div>

                    <div v-if="question.answer.showdd" class="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-20">
                      <!-- Return to Answer Bank -->
                      <div data-test='remove-answer-from-question' class='block px-4 py-2 text-sm text-gray-800 border-b hover:bg-gray-200 cursor-pointer' @click='question.answer.showdd = false; unallocateAnswer(question.answer)'>Return to Answer Bank</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="submitted === true" class='question pair-item' v-bind:class="[submitted ? question.responseClass() : '']">
                {{ question.isCorrect() ? 'Correct': 'Incorrect' }}
              </div>
            </div>
          </div>

          <div>
            <h1>Answers</h1>
            <div class='bg-gray-300 rounded-lg drop-zone'
                @drop='unallocateAnswerEvent($event)' @dragenter.prevent @dragover.prevent
            >
              <div v-for='(answer) in listUnallocatedAnswers' :key='answer.id' data-test='answer' :draggable="submitted === false" @dragstart='startDrag($event, answer)' v-bind:class="[submitted ? '' : 'draggable']" class='pair-item answer'>
                  <div v-html="answer.text"></div>
                  <div class="relative" v-if="submitted === false">
                    <button @click="answer.showdd = !answer.showdd" :data-test='`answer-list-${answer.id}`' class="relative z-10 block rounded px-2 focus:outline-none" aria-haspopup="true">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAA+klEQVRIie2WQQ6CMBBFX4wr9zZ6GhfsPK1nABMCykYX3MErqNEFbWggtLSF6oKfNCFDZ37bmfktLPhzCKCSQ8Qi3QI34CNHDexjk0Yjv0iiu0aqvkuXQCtH4hdNXhPNlkjb2zGWN9SOveC648nwM+L1gF0AJ+AJHAbmnDEfdQpsgCPwGLMYQVupxRiHAZS0VW8VGb1Pa2AXQNyNZezzK31x0Edm8M0svpU+2bW4vNvH5ut0PBY4x9KLy0kGO9DldfQNJmjynRvmFJb/uYwx+bW5SOasxCn9vKlnkKnHg6HLYPchECKvVpiePiHy6kUehVRB5XWWPl0wKb4bnGSKiKb4CgAAAABJRU5ErkJggg=="/>
                      <span class="sr-only assistive-text">Move Answer: {{ answer.text }}</span>
                    </button>

                    <div v-if="answer.showdd" @click="answer.showdd = false" class="fixed inset-0 h-full w-full z-10"></div>

                    <div v-if="answer.showdd" class="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-20">
                      <!-- Move to  Question -->
                      <div data-test='move-answer-to-question' v-for='(question) in questions' :key="question.id" class='block px-4 py-2 text-sm text-gray-800 border-b hover:bg-gray-200 cursor-pointer' @click='moveAnswer(question, answer)'>Move to Question {{ question.id }}</div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="px-8 pt-2 pb-4">
            <button data-test="submit-answers" class="p-2 btn btn-edit" :disabled="submitted === true || listUnallocatedAnswers.length !== 0" @click="submitAnswers">Submit</button>
          </div>

        </div>
      </div>
  </div>

</template>

<script>

import { uniqueId } from 'lodash';
import md5 from 'md5';
import axios from 'axios'

export default {
    name: "DragDrop",
    props: {
        questionsArray: {
            type: Array,
            required: true,
        },
        answersArray: {
            type: Array,
            required: true,
        },
        feedbackText: {
            type: String,
            required: true,
        },
        pageId: {
            type: Number,
            required: true,
        },
        questionText: {
            type: String,
            required: true,
        },
        calId: {
            type: Number,
            required: true,
        },
        public: {
            type: Boolean,
            required: false,
        },
    },
    data() {
      return {
        questions: this.questionsArray.map(question_text => this.initQuestion(question_text)),
        answers: this.answersArray.map(answer => ({ ...answer, id: uniqueId(), showdd: false})),
        submitted: false,
        calScorerElement: null,
      }
    },
    mounted: function () {
      this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
      this.calScorerElement = document.getElementById('cal-score');

    }, 
    computed: {
      listUnallocatedAnswers () {
        return this.answers.filter(answer => {
          return this.getQuestion(answer) === undefined
        });
      },
    },    
    methods: {
        startDrag (evt, answer) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('answerID', answer.id)
        },
        moveAnswer (question, answer) {
          this.answerQuestion(question, answer);
          answer.showdd = false;
        },
        answerQuestionEvent (evt, question) {
            const answerID = evt.dataTransfer.getData('answerID')
            const answer = this.answers.find(answer => answer.id == answerID)
            this.answerQuestion(question, answer);
            this.$nextTick(() => {
              MathJax.typesetPromise();
            });
        },
        answerQuestion (question, answer) {
          this.unallocateAnswer(answer)
          question.answer = answer;
        },
        unallocateAnswerEvent (evt) {
            const answerID = evt.dataTransfer.getData('answerID')
            const answer = this.answers.find(answer => answer.id == answerID)
            this.unallocateAnswer(answer);
            this.$nextTick(() => {
              MathJax.typesetPromise();
            });
        },
        unallocateAnswer (answer) {
            const existing_question = this.getQuestion(answer);
            if (existing_question !== undefined) {
                existing_question.answer = null;
            }
        },
        getQuestion (answer) {
            return this.questions.find(question => {
                return question.answer === answer
            })
        },
        initQuestion (question_text) {
          return  {
            id: uniqueId(),
            text: question_text,
            answer: null,
            isCorrect: function () {
              return this.answer?.md ===  md5(this.text)
            },
            responseClass: function () {
              return this.isCorrect() ? 'bg-green-100' : 'bg-red-100'
            }
          }
        },
        submitAnswers() {
          if (this.submitted === true) {
            return;
          }

          this.submitted = true;
          this.saveAnswer()
              .then(() => this.recordScore());

          this.$nextTick(() => {
            this.removeLegacyLinks(this.$refs.wholePage.getElementsByTagName('a'));
            MathJax.typesetPromise();
          })
        },
        recordScore() {
          axios.post('/scores/' + this.pageId, {
            score: this.getScore(),
            maxScore: this.questions.length,
          }).then(({ data }) => {
            this.calScorerElement.innerHTML = data.score;
          });
        },
        saveAnswer() {
          let submitUrl = this.public ? '/public/cals/' + this.calId + '/pages/' + this.pageId + '/submit' : '/cals/' + this.calId + '/pages/' + this.pageId + '/submit';
          
          return axios.post(submitUrl, {
            inputAnswer: this.questions.map( (option => {
              return {
                question: option.text,
                answer: option.answer.text,
              };
            })),
            score: this.getScore(),
          })
        },
        getScore() {
          return this.questions.reduce((total, question) => (question.isCorrect() ? 1 : 0) + total,0)
        }
    },
}

</script>

<style scoped>

 .drop-zone {
    margin-bottom: 20px;
    padding: 10px;
    min-height: 90px;
  }

  .draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }

  .draggable:active {
    cursor: grabbing;
  }

</style>