<template>
    <div class="md:flex">
        <label class="sr-only assistive-text" :for="`cb-${option.index}`">Option {{ option.index }}</label><br>
        <input class="transform scale-150 my-2" :data-test="`checkbox-option-${option.index}`" :aria-label="`checkbox-option-${option.index}`" type="checkbox" :id="`cb-${option.index}`" :name="`response-${option.index}`" value="true" @change="$emit('answerSelected', option, $event.currentTarget.checked | 0)">
        <span class="md:hidden ml-2">Select</span>
    </div>
</template>

<script>

export default {
  name: "McqMultipleAnswer",
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
}

</script>
