export default {
  install: (app, options) => {
    app.mixin({
      methods: {
        removeLegacyLinks(aTags) {
          Array.from(aTags).forEach(function(aTag) {
            if(aTag.href.includes('eros.mvm.ed.ac.uk') || aTag.href.includes('eemec.med.ed.ac.uk') || aTag.href.includes('javascript')){
              aTag.outerHTML = '<span class="italic text-red-900">[this link is not available, please contact the CAL owner]</span>';
            }
          })
        },
        pluralizeWord(counter, word) {
          return counter === 1 ? word : word + 's'; 
        },
      }
    })
  }
}